import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import {
  Layout,
  Articles,
  Contracts,
  Products,
  Highlights,
  Trainings,
  VideoHero,
  QuickNav,
  Wrapper,
  Title,
  Subtitle,
} from '../components'
import website from '../../config/website'

import { Amplitude, LogOnMount } from '@amplitude/react-amplitude'

const IndexWrapper = Wrapper.withComponent('main')

function Index(props) {
  const {
    data: {
      homepage,
      posts,
      contracts,
      products,
      servicesHighlights,
      smartcitiesHighlights,
      trainings,
    },
  } = props

  return (
    <Layout withVideoHeading threshold={0.65}>
      <Amplitude
        eventProperties={{
          scope: ['homepage'],
          title: website.title,
          url: website.pathPrefix,
        }}
      >
        <LogOnMount eventType="homepage view" />
        <VideoHero
          video={homepage.data.video_header_link.url}
          poster={homepage.data.poster_header_link.url}
          content={homepage.data.content}
        ></VideoHero>
        <IndexWrapper id={website.skipNavId} style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
          <QuickNav></QuickNav>

          <Title id="noticias" style={{ paddingTop: '1rem' }}>
            Noticias
          </Title>
          <Articles posts={posts.edges} />

          <Title id="adjudicaciones" style={{ paddingTop: '6rem' }}>
            Adjudicaciones
          </Title>
          <Contracts contracts={contracts.edges} />

          <Title id="productos" style={{ paddingTop: '6rem' }}>
            {products.data.product_header.text}
          </Title>
          <Products products={products.data.body} />

          <Title id="servicios" style={{ paddingTop: '6rem' }}>
            {servicesHighlights.data.services_header.text}
          </Title>
          <Subtitle>{servicesHighlights.data.services_subheader.text}</Subtitle>
          <Highlights highlights={servicesHighlights.data.body1} />

          <Title id="smartcities" style={{ paddingTop: '6rem' }}>
            {smartcitiesHighlights.data.smartcities_header.text}
          </Title>
          <Subtitle>{smartcitiesHighlights.data.smartcities_subheader.text}</Subtitle>
          <Img fluid={homepage.data.artwork.localFile.childImageSharp.fluid} />
          <Highlights highlights={smartcitiesHighlights.data.body2} />

          <Title id="formacion" style={{ paddingTop: '6rem' }}>
            Formación
          </Title>
          <Trainings trainings={trainings.edges} />
        </IndexWrapper>
      </Amplitude>
    </Layout>
  )
}

export default Index

Index.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.object.isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query IndexQuery {
    homepage: prismicHomepage {
      data {
        title {
          text
        }
        content {
          html
        }
        video_header_link {
          url
        }
        poster_header_link {
          url
        }
        artwork {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    products: prismicHomepage {
      data {
        product_header {
          text
        }
        body {
          ... on PrismicHomepageBodyProduct {
            slice_type
            id
            primary {
              name {
                text
              }
              summary {
                text
              }
              icon {
                alt
                url
                localFile {
                  childImageSharp {
                    fixed(width: 64, height: 64, quality: 90) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
              screenshot {
                alt
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 300, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              link {
                link_type
                url
                target
              }
            }
          }
        }
      }
    }
    servicesHighlights: prismicHomepage {
      data {
        services_header {
          text
        }
        services_subheader {
          text
        }
        body1 {
          ... on PrismicHomepageBody1Highlight {
            slice_type
            id
            primary {
              name {
                text
              }
              summary {
                text
              }
              icon {
                alt
                url
                localFile {
                  childImageSharp {
                    fixed(width: 64, height: 64, quality: 90) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
              link {
                link_type
                url
                target
              }
            }
          }
        }
      }
    }
    smartcitiesHighlights: prismicHomepage {
      data {
        smartcities_header {
          text
        }
        smartcities_subheader {
          text
        }
        body2 {
          ... on PrismicHomepageBody2Highlight {
            slice_type
            id
            primary {
              name {
                text
              }
              summary {
                text
              }
              icon {
                alt
                url
                localFile {
                  childImageSharp {
                    fixed(width: 64, height: 64, quality: 90) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
              link {
                link_type
                url
                target
              }
            }
          }
        }
      }
    }
    posts: allPrismicPost(limit: 6, sort: { fields: [data___date], order: DESC }) {
      edges {
        node {
          uid
          type
          data {
            title {
              text
            }
            description
            featured_image {
              alt
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 500, maxHeight: 300, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            date(formatString: "DD/MM/YYYY")
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    type
                    slugs
                    data {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    contracts: allPrismicContract(limit: 4, sort: { order: DESC, fields: [data___date] }) {
      edges {
        node {
          id
          data {
            customer {
              text
            }
            link {
              url
            }
            title {
              text
            }
            value {
              text
            }
          }
          id
        }
      }
    }
    trainings: allPrismicTraining(
      limit: 6
      filter: { data: { active: { eq: "true" } } }
      sort: { order: DESC, fields: [data___reference] }
    ) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            description {
              text
            }
            reference
            type
            target {
              text
            }
            trainer {
              text
            }
          }
          id
        }
      }
    }
  }
`
